.version-name-cl {
    font-size: 1.8em;
    font-weight: 300;
}

.changelog-text-md h1 {
    font-weight: 300;
}

.changelog-text-md h2 {
    font-weight: 300;
}

.changelog-text-md {
    font-size: 1em;
}

.changelog-text-md ul {
    list-style-type: circle;
}

article .heading-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

article .heading-box .heading-title-box {
    display: flex;    
    justify-content: space-between;
    flex-direction: column;
}

.btn-styled-cqr {
    font-size: 1.6rem;    
}