header {
    text-align: left;
    font-size: 2.0em;
    font-weight: 1;
    padding-bottom: 0.5em;
}

article {
    padding: 0.75em;
    padding-left: 0em;
    text-align: left;
    font-weight: 1;
    font-size: 1.05em;

}

.slideshow-with-description-left {
    padding: 0em;
}

.slideshow-with-description-right {
    padding: 0em;
}

div .slideshow-with-description {
    padding-bottom: 1em;
    flex-flow: column;
    flex-direction: column;
    display: flex;
}

.slideshow-with-description .slideshow-container {
    width: 100%;
    height: 100%;
}

@media only screen and (min-width: 768px) {
    header {
        font-size: 2.5em;
    }
    article {
        font-size: 1.5em;
    }
    .slideshow-with-description-left {
        padding: 0em;
        padding-right: 0.75em;
    }
    
    .slideshow-with-description-right {
        padding: 0em;
        padding-left: 0.75em;
    }

    div .slideshow-with-description {
        flex-flow: row;
        flex-direction: row;
    }

    div .slideshow-with-description.order-reverse {
        flex-flow: row-reverse;
        flex-direction: row-reverse;
    }

    .slideshow-with-description .slideshow-container {
        width: 50%;
        height: 50%;
    }
}

.slideshow-with-description article {
    width: 100%;
}