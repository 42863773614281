.main-footer {
    color: rgba(255, 213, 79);
    background-color: rgb(34, 34, 34);
    width: 100%;
    position: bottom;
}

.container {
    padding: 0.1em;
    position: flex;
}
/*
.content {
    position: center;
}
*/
.list-ul {
    display: flex;
    flex-flow: row;
    flex-direction: row;
    justify-content: center;
}

.navigation-item-f {
    font-size: 1.4em;
    padding: 0.4em;
    font-family: Arial, Helvetica, sans-serif;
    color: rgba(255, 213, 79);
    text-decoration: none;
    transition: 0.25s;
    transform: scale(1.0);
}

.list-ul a:hover {
    transition: 0.5s;
    transform: scale(1.05);
}
