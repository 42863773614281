.member-section {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
}

.member-card {
    padding: 1rem;
}

.heading {
    font-size: 3em;
}