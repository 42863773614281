/* Slideshow stuff */
/*
@import "react-slideshow-image/dist/styles.css";

*/
.each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.each-slide img {
  width:100%;
  height:inherit;
}

.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* Custom styles */

.content-table {
  text-align: center;
  width: 100%;
}

div .page-content {
  width: 95%;
  padding-left: 2.5%;
}

/*
768px width: 95%, 2.5% padding
1920px width: 60%, 20% padding
*/
@media only screen and (min-width: 768px) {
  div .page-content {
    width: 60%;
    padding-left: 20%;
  }
}

.bg-side {
  width: 20%;
  background-color: #efefef;
}

.centered {
  justify-content: center;
}

/* Dashed border */
hr.line-dashed {
  border: 3px dashed #bbb;
}

/* Dotted border */
hr.dotted {
  border: 3px dotted #bbb;
}

/* Solid border */
hr.line-solid {
  border: 3px solid #bbb;
}

/* Rounded border */
hr.line-rounded {
  border: 8px solid #bbb;
  border-radius: 5px;
} 

/* The fancy button */
.btn-styled-cqr {
	background-color: #ffd54f;
	border: 2.5rem;
	border-radius: 8px;
	border-color: none;
	color: gray;
	padding: 12px 30px;
	cursor: pointer;
	font-size: 20px;
	font-weight: bold;
}

.btn-styled-cqr:hover {
	background-color: rgba(55, 179, 166, 1.0);
  transform: scale(1.05);
  color: white;
	transition: 0.25s;
}